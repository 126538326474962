import * as React from "react";
import axios from 'axios';
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import GroupsIcon from '@mui/icons-material/Groups';
import DataTable from 'react-data-table-component';
import { APIURL } from '../Global';
const token = localStorage.getItem('codeDcUserToken');


function SurveysTab({ 
  handleNodeSelect, 
  handleTeamMemberClick, 
  selectedGroup, 
  selectedGroupId, 
  teamMembers 
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [formTabIndex, setFormTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newContact, setNewContact] = useState({
    group: selectedGroupId,
    sl_no: '',
    house_no: '',
    house_name: '',
    family_name: '',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    education: '',
    occupation: '',
    marital_status: 'single',
    vehicles: '',
    differently_abled: '',
    chronic_disease: '',
    religion: '',
    caste: '',
    sub_caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: 'no',
    aadhaar_number: '',
    email: '',
    phone: '',
    eligible_to_vote:'no'
  });
  const [currentContact, setCurrentContact] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const MARITAL_STATUS_CHOICES = ['single', 'married', 'divorced', 'widowed'];
  const GENDER_CHOICES = ['choose an option',['M', 'Male'], ['F', 'Female'], ['O', 'Transgender']];
  const PENSION_CHOICES = [ 'yes', 'no'];
  const VOTE_CHOICES = [ 'yes', 'no'];
  

  const [houseModalOpen, setHouseModalOpen] = useState(false);
const [newHouseData, setNewHouseData] = useState({
  group: '',
  house_no: '',
  house_name: '',
  family_name: '',
  total_member: '',
  total_voters: '',
  land: '',
  house_status: '',
  electricity: 'no',
  toilet: 'no',
  road_access: 'no',
  drinking_water_source: '',
  toilet: '',
  waste_management: '',
  ration_card_type: '',
  ration_card_number: ''
});
const [houseData, setHouseData] = useState([]);
const [currentHouseData, setCurrentHouseData] = useState(null);
const [editHouseModalOpen, setEditHouseModalOpen] = useState(false);
const [familyData, setFamilyData] = useState([]);
const [newFamilyData, setNewFamilyData] = useState({
  family_name: '',
  total_family_households: '',
  total_family_members: '',
  total_family_voters: '',
  total_family_national_expatriates: '',
  total_family_international_expatriates: ''
});
const [currentFamilyData, setCurrentFamilyData] = useState(null);
const [familyModalOpen, setFamilyModalOpen] = useState(false);
const [editFamilyModalOpen, setEditFamilyModalOpen] = useState(false);
const BOOLEAN_CHOICES = ['yes', 'no'];


const formatDate = (date) => {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

const displayDate = (date) => {
  if (!date) return '';
  const parts = date.split('-');
  if (parts.length !== 3) return date;
  const [day, month, year] = parts;
  return `${year}-${month}-${day}`;
};


const addRow = async () => {
  const mandatoryFields = ['group', 'name', 'gender','house_no', 'house_name','guardian_name'];
  
  const emptyMandatoryFields = mandatoryFields.filter(field => !newContact[field]);

  if (emptyMandatoryFields.length > 0) {
    alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
    return;
  }

  const dataToSend = { 
    ...newContact,
    dob: formatDate(newContact.dob)
  };
  delete dataToSend.age;

  try {
    const response = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(dataToSend) 
    });

    const data = await response.json();
    
    if (data.status === 'success') {
      alert(data.message || 'New data added');
      setContacts([...contacts, newContact]);
      setModalOpen(false);
      setNewContact({
        group: selectedGroupId,
        sl_no: '',
    house_no: '',
    house_name: '',
    family_name: '',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    education: '',
    occupation: '',
    marital_status: 'single',
    vehicles: '',
    differently_abled: '',
    chronic_disease: '',
    religion: '',
    caste: '',
    sub_caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: 'no',
    aadhaar_number: '',
    email: '',
    phone: '',
    eligible_to_vote:'no'
      });
    } else {
      
      alert(data.message || 'Failed to add new data');
     
    }
  } catch (error) {
    console.error('Error adding new contact:', error);
    alert('An error occurred while adding the contact');
  }
};


const fetchIndividuals = async (groupId) => {
  try {
    const response = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${groupId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      setContacts(data.data || []);
    } else {
      setContacts([]);
    }
  } catch (error) {
    console.error("Error fetching individuals:", error);
    setContacts([]);
  }
};


useEffect(() => {
  if (selectedGroupId && typeof selectedGroupId === 'number') {
    fetchIndividuals(selectedGroupId);
    fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
  }
}, [selectedGroupId]);



  const editRow = (index) => {
    setCurrentContact({ ...contacts[index], index });
    setEditModalOpen(true);
  };

  const updateRow = async () => {
    try {
 
      const statusMap = {
        "Inactive": 0,
        "Active": 1,
      };
  
      const updatedContact = {
        ...currentContact,
        status: statusMap[currentContact.status],
        dob: formatDate(currentContact.dob)
      };

      delete updatedContact.age;
  
      const response = await fetch(`${APIURL}/data_collection/api/update-individuals/?individual_id=${updatedContact.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(updatedContact)
      });
  
      const data = await response.json();
  
      if (data.status === 'success') {
        setContacts(contacts.map(contact =>
          contact.id === updatedContact.id ? updatedContact : contact
        ));
        setEditModalOpen(false);
        alert(data.message);
      } else {
        alert(data.message || 'Update failed');
      }
    } catch (error) {
      console.error('Update error:', error);
      alert('Update failed');
    }
  };
  
  
  
  

  const handleChange = (e, setContact) => {
    const { name, value } = e.target;
    setContact(prevState => {
      const newState = { ...prevState };
  
      if (name === 'dob') {
        newState.dob = value;
        
        // Calculate age
        const [year, month, day] = value.split('-');
        const dob = new Date(year, month - 1, day);
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
          age--;
        }
        newState.age = age.toString();
      } else if (name === 'age') {
        newState.age = value;
        
        // Calculate DOB
        const today = new Date();
        const dob = new Date(today.getFullYear() - parseInt(value), today.getMonth(), today.getDate());
        const year = dob.getFullYear();
        const month = String(dob.getMonth() + 1).padStart(2, '0');
        const day = String(dob.getDate()).padStart(2, '0');
        newState.dob = `${year}-${month}-${day}`;
      } else {
        newState[name] = value;
      }
      
      return newState;
    });
  };


  // const deleteRow = (index) => {
  //   if (window.confirm('Are you sure you want to delete this contact?')) {
  //     const updatedContacts = contacts.filter((_, i) => i !== index);
  //     setContacts(updatedContacts);
  //   }
  // };




  

  
    const deleteRow = async (individualId) => {
      if (window.confirm('Are you sure you want to delete this contact?')) {
        try {
          const response = await fetch(`${APIURL}/data_collection/api/individuals/delete/?individual_id=${individualId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ id: individualId, confirm:true })
          });
    
          const data = await response.json();
    
          if (data.status === 'success') {
            alert(data.message);
           
            setContacts(contacts.filter(contact => contact.id !== individualId));
          } else {
            alert(data.message || 'An error occurred while marking the individual as inactive');
          }
        } catch (error) {
          console.error('Error deleting individual:', error);
          alert('An error occurred while deleting the contact');
        }
      }
    };
  
  
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

 
  

  const addHouseData = async () => {
    const mandatoryFields = ['group', 'house_no', 'house_name', 'family_name', 'total_member', 'total_voters', 'ration_card_type', 'ration_card_number'];
    const emptyMandatoryFields = mandatoryFields.filter(field => !newHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
  
    if (!/^[1-9]\d*$/.test(newHouseData.house_no)) {
      alert('House number must be a positive integer');
      return;
    }
  
    try {
      const houseDataToSubmit = {
        ...newHouseData,
        house_no: parseInt(newHouseData.house_no, 10),
        electricity: newHouseData.electricity === 'no',
        toilet: newHouseData.toilet === 'no',
        road_access: newHouseData.road_access === 'no'
      };
  
      const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${selectedGroupId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(houseDataToSubmit)
      });
  
      const data = await response.json();
      
      if (data.status === 'success') {
        alert(data.message || 'New house data added');
        fetchHouseData(selectedGroupId);
        setHouseModalOpen(false);
        setNewHouseData({
          group: selectedGroupId,
          house_no: '',
          house_name: '',
          family_name: '',
          total_member: '',
          total_voters: '',
          land: '',
          house_status: '',
          electricity: 'no',
          toilet: 'no',
          road_access: 'no',
          drinking_water_source: '',
          waste_management: '',
          ration_card_type: '',
          ration_card_number: ''
        });
      } else {
        alert(data.message || 'Failed to add new house data');
      }
    } catch (error) {
      console.error('Error adding new house data:', error);
      alert('An error occurred while adding the house data');
    }
  };
  const fetchHouseData = async (groupId) => {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${groupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        setHouseData(data.data || []);
      } else {
        setHouseData([]);
      }
    } catch (error) {
      console.error("Error fetching house data:", error);
      setHouseData([]);
    }
  };


  const customStyles = {
    table: {
      style: {
        width: '99%',
        borderCollapse: 'collapse',
        minWidth: '600px',
        backgroundColor: '#E4DD9F85',
        borderRadius: '50px',
        marginLeft:'5px'
      },
    },
    rows: {
      style: {
        minHeight: '50px',
        backgroundColor: '#E4DD9F85',
        
        
      },
    },
    headCells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '4px',
        backgroundColor: '#344771',
        border: '2px solid #FFFFFF',
        color: '#ffffff',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '4px', 
        border: '2px solid #FFFFFF',
        padding: '8px',
        backgroundColor: '#DCE9F7',

      },
    },
  };


  
  const updateHouseData = () => {
    const mandatoryFields = ['group', 'house_no', 'house_name', 'total_member', 'total_voters', 'ration_card_type', 'ration_card_number'];
    const emptyMandatoryFields = mandatoryFields.filter(field => !currentHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
    
    const updatedHouseData = houseData.map((house, index) => 
      index === currentHouseData.index ? {...currentHouseData} : house
    );
    setHouseData(updatedHouseData);
    setEditHouseModalOpen(false);
    setCurrentHouseData(null);
  };

  const editHouseRow = (index) => {
    setCurrentHouseData({ ...houseData[index], index });
    setEditHouseModalOpen(true);
  };
    
    const deleteHouseRow = (index) => {
      if (window.confirm('Are you sure you want to delete this house data?')) {
        const updatedHouseData = houseData.filter((_, i) => i !== index);
        setHouseData(updatedHouseData);
      }
    };


    const addFamilyData = async () => {
      const mandatoryFields = ['family_name', 'total_family_households', 'total_family_members', 'total_family_voters'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !newFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
    
      try {
        console.log("Data being sent to API:", JSON.stringify(newFamilyData));
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${selectedGroupId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(newFamilyData)
        });
    
        const data = await response.json();
        
        if (data.status === 'success') {
          alert(data.message || 'New family data added');
          fetchFamilyData(selectedGroupId);
          setFamilyModalOpen(false);
          setNewFamilyData({
            family_name: '',
            total_family_households: '',
            total_family_members: '',
            total_family_voters: '',
            total_family_national_expatriates: '',
            total_family_international_expatriates: ''
          });
        } else {
          alert(data.message || 'Failed to add new family data');
        }
      } catch (error) {
        console.error('Error adding new family data:', error);
        alert('An error occurred while adding the family data');
      }
    };
    

    const fetchFamilyData = async (groupId) => {
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${groupId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const data = await response.json();
        if (data.status === "success") {
          setFamilyData(data.data || []);
        } else {
          setFamilyData([]);
        }
      } catch (error) {
        console.error("Error fetching family data:", error);
        setFamilyData([]);
      }
    };


    const updateFamilyData = () => {
      const mandatoryFields = ['households_in_family', 'total_members', 'total_voters'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !currentFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
      
      const updatedFamilyData = familyData.map((family, index) => 
        index === currentFamilyData.index ? {...currentFamilyData} : family
      );
      setFamilyData(updatedFamilyData);
      setEditFamilyModalOpen(false);
      setCurrentFamilyData(null);
    };
    
    const editFamilyRow = (index) => {
      setCurrentFamilyData({ ...familyData[index], index });
      setEditFamilyModalOpen(true);
    };
    
    const deleteFamilyRow = (index) => {
      if (window.confirm('Are you sure you want to delete this family data?')) {
        const updatedFamilyData = familyData.filter((_, i) => i !== index);
        setFamilyData(updatedFamilyData);
      }
    };


    // async function handleFileUpload() {
    //   const input = document.createElement('input');
    //   input.type = 'file';
    //   input.accept = '.xlsx,.xls,.csv';
    
    //   input.onchange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //       console.error('No file selected');
    //       return;
    //     }
    
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('group_id', selectedGroupId); // Using the selectedGroupId from the component's state
    
    //     try {
    //       const response = await axios.post(`${APIURL}/data_collection/api/upload-individuals/`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Token ${token}` // Using the token from localStorage
    //         }
    //       });
    
    //       if (response.data.status === 'success') {
    //         console.log(response.data.message);
    //         alert(response.data.message); // Show success message to the user
    //         // Optionally, you can update the contacts state here if needed
    //         fetchIndividuals(selectedGroupId); // Refresh the individuals list
    //       } else {
    //         console.error('Upload failed:', response.data.message);
    //         alert(`Upload failed: ${response.data.message}`); // Show error message to the user
    //       }
    //     } catch (error) {
    //       console.error('Error uploading file:', error);
    //       alert('Error uploading file. Please try again.'); // Show general error message
    //     }
    //   };
    
    //   input.click();
    // }

 
  
    const handleFormTabChange = (event, newIndex) => {
      setFormTabIndex(newIndex);
    };
  
    const renderFormFields = (fields, contactData, setContactData) => {
      if (!contactData) return null;
    
      return fields.map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field === 'sl_no' ? 'Sl.No (as per voters list)' : field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['group', 'name', 'gender','house_no', 'house_name','guardian_name'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          {field === 'dob' ? (
        <TextField
          name={field}
          type="date"
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          onChange={(e) => handleChange(e, setContactData)}
          className="form-field"
          error={!!formErrors[field]}
          helperText={formErrors[field]}
          InputLabelProps={{ shrink: true }}
        />
      ) : field === 'age' ? (
        <TextField
          name={field}
          type="number"
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          onChange={(e) => handleChange(e, setContactData)}
          className="form-field"
          error={!!formErrors[field]}
          helperText={formErrors[field]}
          inputProps={{ min: 0, max: 150 }}
        />
          ) : field === 'age' ? (
            <TextField
              name={field}
              type="number"
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              inputProps={{ min: 0, max: 150 }}
            />
          ) : field === 'gender' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['group', 'name', 'gender','house_no', 'house_name','guardian_name'].includes(field)}
            >
              {GENDER_CHOICES.map((choice) => (
                <MenuItem key={choice} value={Array.isArray(choice) ? choice[0] : choice}>
                  {Array.isArray(choice) ? choice[1] : choice}
                </MenuItem>
              ))}
            </Select>
          ) : field === 'marital_status' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['group', 'name', 'gender','house_no', 'house_name','guardian_name'].includes(field)}
            >
              {MARITAL_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'pension' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['group', 'name', 'gender','house_no', 'house_name','guardian_name'].includes(field)}
            >
              {PENSION_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'eligible_to_vote' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['group', 'name', 'gender','house_no', 'house_name','guardian_name'].includes(field)}
            >
              {VOTE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={
                field === 'dob' ? 'date' : 
                field === 'email' ? 'email' : 
                field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number' ? 'number' : 
                'text'
              }
              inputProps={
                field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number'
                  ? { step: 1, min: 1 } 
                  : {}
              }
              fullWidth
              variant="outlined"
              value={field === 'dob' ? displayDate(contactData[field]) : contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              required={['name', 'gender','house_no','house_name','guardian_name'].includes(field)}
            />
          )}
        </Grid>
      ));
    };
  
  return (
    <div>
     <Box sx={{ 
      borderColor: 'divider', 
      backgroundColor: '#E6E6FA', 
      borderRadius: '8px',
      marginBottom: '30px',
      overflowX: 'auto', 
      display: 'flex'
    }}>
      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        aria-label="dashboard tabs" 
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#034694', 
          },
        }}
      >
    
        <Tab 
          icon={<PersonIcon />} 
          iconPosition="start" 
          label="INDIVIDUAL DATA" 
          sx={{
            color: tabIndex === 0 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
        <Tab 
          icon={<HouseIcon />} 
          iconPosition="start" 
          label="HOUSEHOLD DATA" 
          sx={{
            color: tabIndex === 1 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
        <Tab 
          icon={<GroupsIcon />} 
          iconPosition="start" 
          label="FAMILY DATA" 
          sx={{
            color: tabIndex === 2 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
      </Tabs>
    </Box>

                {tabIndex === 0 && (
                    <div>
                
                    <ButtonContainer>
  {/* <AddButton onClick={handleFileUpload}>
    Add File &nbsp; <b>+</b>
  </AddButton>&nbsp;&nbsp; */}
  <AddButton onClick={() => setModalOpen(true)}>
    Add Data &nbsp; <b>+</b>
  </AddButton>
</ButtonContainer>
<DataTable
  columns={[
    {
      name: 'Sl.No',
      selector: row => row.sl_no,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Guardian Name',
      selector: row => row.guardian_name,
      sortable: true,
    },
    {
      name: 'House No',
      selector: row => row.house_no,
      sortable: true,
    },
    {
      name: 'House Name',
      selector: row => row.house_name,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      sortable: true,
    },
    {
      name: 'Age',
      selector: row => row.age,
      sortable: true,
    },
    {
      name: 'Epic No',
      selector: row => row.epic_no,
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        <EditIcon onClick={() => editRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </EditIcon>
      ),
      width: '60px',
    },
    {
      name: 'Delete',
      cell: (row, index) => (
        <DeleteIcon onClick={() => deleteRow(row.id)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={18} />
        </DeleteIcon>
      ),
      width: '60px',
    },
   
  ]}
  data={contacts}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>

            
<Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Add New Data</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={formTabIndex} onChange={handleFormTabChange}>
              <Tab label="A" />
              <Tab label="B" />
              <Tab label="C" />
            </Tabs>
          </Box>
          <Grid container spacing={2}>
            {formTabIndex === 0 && renderFormFields([
              'group', 'house_no', 'house_name', 'family_name', 'sl_no', 'name',
              'guardian_name', 'guardian_relation', 'dob', 'age', 'gender', 'epic_no'
            ], newContact, setNewContact)}
            {formTabIndex === 1 && renderFormFields([
              'pension', 'aadhaar_number', 'email', 'phone', 'eligible_to_vote',
              'education', 'occupation', 'marital_status', 'vehicles',
              'differently_abled', 'chronic_disease'
            ], newContact, setNewContact)}
            {formTabIndex === 2 && renderFormFields([
              'religion', 'caste', 'sub_caste', 'religious_organizations',
              'social_organizations', 'political_affiliation_party',
              'political_affiliation_status'
            ], newContact, setNewContact)}
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
    <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit
    </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={formTabIndex} onChange={handleFormTabChange}>
              <Tab label="A" />
              <Tab label="B" />
              <Tab label="C" />
            </Tabs>
          </Box>
          <Grid container spacing={2}>
            {formTabIndex === 0 && renderFormFields([
              'group', 'house_no', 'house_name', 'family_name', 'sl_no', 'name',
              'guardian_name', 'guardian_relation', 'dob', 'age', 'gender', 'epic_no'
            ], currentContact, setCurrentContact)}
            {formTabIndex === 1 && renderFormFields([
              'pension', 'aadhaar_number', 'email', 'phone', 'eligible_to_vote',
              'education', 'occupation', 'marital_status', 'vehicles',
              'differently_abled', 'chronic_disease'
            ], currentContact, setCurrentContact)}
            {formTabIndex === 2 && renderFormFields([
              'religion', 'caste', 'sub_caste', 'religious_organizations',
              'social_organizations', 'political_affiliation_party',
              'political_affiliation_status'
            ], currentContact, setCurrentContact)}
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setEditModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
    <Button onClick={updateRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Update
    </Button>
        </DialogActions>
      </Dialog>
                  </div>
                    
                  )}
                  {tabIndex === 1 && (
                    <div>
        <ButtonContainer>
        <AddButton  onClick={() => setHouseModalOpen(true)}> Add Data &nbsp; <b>+</b> </AddButton>
      </ButtonContainer>
      <DataTable
  columns={[
   
   
    {
      name: 'House No',
      selector: row => row.house_no,
      sortable: true,
    },
    {
      name: 'House Name',
      selector: row => row.house_name,
      sortable: true,
    },
    {
      name: 'Total Member',
      selector: row => row.total_member,
      sortable: true,
    },
    {
      name: 'Total Voters',
      selector: row => row.total_voters,
      sortable: true,
    },
    {
      name: 'Ration Card Type',
      selector: row => row.ration_card_type,
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        <EditIcon onClick={() => editHouseRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </EditIcon>
      ),
      width: '60px',
    },
    {
      name: 'Delete',
      cell: (row, index) => (
        <DeleteIcon onClick={() => deleteHouseRow(index)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={18} />
        </DeleteIcon>
      ),
      width: '60px',
    },
   
  ]}
  data={houseData}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


     {/* Add House Data Dialog */}
     <Dialog open={houseModalOpen} onClose={() => setHouseModalOpen(false)}>
  <DialogTitle>Add House Data</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
    {Object.keys(newHouseData).map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['group', 'house_no', 'house_name', 'family_name', 'total_member', 'total_voters', 'ration_card_type', 'ration_card_number'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          {['toilet', 'electricity', 'road_access'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={newHouseData[field]}
              onChange={(e) => handleChange(e, setNewHouseData)}
              className="form-field"
            >
              {BOOLEAN_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice.charAt(0).toUpperCase() + choice.slice(1)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={field === 'house_no' || field === 'total_member' || field === 'total_voters' || field === 'ration_card_number' ? 'number' : 'text'}
              fullWidth
              variant="outlined"
              value={newHouseData[field]}
              onChange={(e) => handleChange(e, setNewHouseData)}
              className="form-field"
              InputProps={field === 'house_no' ? { inputProps: { min: 1 } } : {}}
              required={['group', 'house_no', 'house_name', 'family_name', 'total_member', 'total_voters', 'ration_card_type', 'ration_card_number'].includes(field)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={addHouseData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit
    </Button>
    <Button onClick={() => setHouseModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
  </DialogActions>
</Dialog>

{/* Edit House Data Dialog */}
<Dialog open={editHouseModalOpen} onClose={() => setEditHouseModalOpen(false)}>
  <DialogTitle>Edit House Data</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
    {currentHouseData && Object.keys(currentHouseData).filter(key => key !== 'index').map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['group', 'house_no', 'house_name', 'total_member', 'total_voters', 'ration_card_type', 'ration_card_number'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          {['toilet', 'electricity', 'road_access'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {BOOLEAN_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice.charAt(0).toUpperCase() + choice.slice(1)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={field === 'total_member' || field === 'total_voters' || field === 'ration_card_number' ? 'number' : 'text'}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            />
          )}
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={updateHouseData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Update
    </Button>
    <Button onClick={() => setEditHouseModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
  </DialogActions>
</Dialog>

      </div>
          )}
         {tabIndex === 2 && (
  <div>
    <ButtonContainer>
      <AddButton onClick={() => setFamilyModalOpen(true)}> Add Data &nbsp; <b>+</b> </AddButton>
    </ButtonContainer>
    <DataTable
  columns={[
   
    {
      name: 'Family Name', 
      selector: row => row.family_name,
      sortable: true,
    },
    {
      name: 'Total Households',
      selector: row => row.total_family_households,
      sortable: true,
    },
    {
      name: 'Total Members',
      selector: row => row.total_family_members,
      sortable: true,
    },
    {
      name: 'Total Voters',
      selector: row => row.total_family_voters,
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        <EditIcon onClick={() => editHouseRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </EditIcon>
      ),
      width: '60px',
    },
    {
      name: 'Delete',
      cell: (row, index) => (
        <DeleteIcon onClick={() => deleteHouseRow(index)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={18} />
        </DeleteIcon>
      ),
      width: '60px',
    },
   
  ]}
  data={familyData}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


    {/* Add Family Data Dialog */}
    <Dialog open={familyModalOpen} onClose={() => setFamilyModalOpen(false)}>
  <DialogTitle>Add Family Data</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {Object.keys(newFamilyData).map((field) => (
        <Grid item xs={12} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['family_name', 'total_family_households', 'total_family_members', 'total_family_voters'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          <TextField
            name={field}
            type={field === 'family_name' ? 'text' : 'number'}
            fullWidth
            variant="outlined"
            value={newFamilyData[field]}
            onChange={(e) => handleChange(e, setNewFamilyData)}
            className="form-field"
            required={['family_name', 'total_family_households', 'total_family_members', 'total_family_voters'].includes(field)}
          />
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={addFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit
    </Button>
    <Button onClick={() => setFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
  </DialogActions>
</Dialog>

    {/* Edit Family Data Dialog */}
    <Dialog open={editFamilyModalOpen} onClose={() => setEditFamilyModalOpen(false)}>
      <DialogTitle>Edit Family Data</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {currentFamilyData && Object.keys(currentFamilyData).filter(key => key !== 'index').map((field) => (
            <Grid item xs={12} key={field}>
              <Typography variant="body1" className="field-label">
                {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                <span style={{ color: 'red' }}> *</span>
              </Typography>
              <TextField
                name={field}
                type="number"
                fullWidth
                variant="outlined"
                value={currentFamilyData[field]}
                onChange={(e) => handleChange(e, setCurrentFamilyData)}
                className="form-field"
                required
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
          Update
        </Button>
        <Button onClick={() => setEditFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)}
        </div>        
  );
}


const DeleteIcon = styled.span`
  cursor: pointer;
  margin-left: 10px;
`;
// const TableContainer = styled.div`
//   width: 100%;
//   overflow-x: auto;
//   background-color:#1877F21A;
//   border-radius:15px;
// ;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   min-width: 600px;
// `;

const ButtonContainer = styled.div`
  background-color: #6F89B7;
  padding: 13px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
   margin-bottom: 2px;
`;
// const TableCell = styled.td`
//   border: 4px solid #FFFFFF;
//   padding: 16px;
// `;

// const TableRow = styled.tr`
  
//     background-color: #1877F21A;


// `;

const EditIcon = styled.span`
  cursor: pointer;
`;


const AddButton = styled.button`
  padding: 10px 30px;
  background-color: #FFFFFF;
  color: #133E88;
  border: none;
  border-radius: 34px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;


// const FileButton = styled.button`
//   padding: 10px 30px;
//   background-color: #FFFFFF;
//   color: #133E88;
//   border: none;
//   border-radius: 34px;
//   font-weight: 700;
//   font-size: 18px;
//   cursor: pointer;
//   margin-right: 10px;
//   &:hover {
//     background-color: #cccccc;
//   }
// `;


export default SurveysTab;